import { create } from '@storybook/theming';

const BRAND_COLOR = {
  PINK: '#CA307F',
  TEAL: '#007F8A',
  PURPLE: '#663c97',
  INK: '#170e3f',
  GREY: '#3b3b3b'
}

export default create({
  base: 'light',

  colorPrimary: BRAND_COLOR.PURPLE,
  colorSecondary: BRAND_COLOR.TEAL,

  brandTitle: 'Blackbullion Storybook',
  brandUrl: 'https://blackbullion.com',
  brandImage: 'https://www.blackbullion.com/assets/images/logos/blackbullion--full--black.svg',
});

